


































































































































import { Vue, Component } from 'vue-property-decorator'
import reportService, { FlexTrade } from '@/services/reportService'
import utility from '@/common/utility'
import { Sort } from '@/services/data'
import SortHeader from '@/components/SortHeader.vue'

let pageTrades: FlexTrade[] = []
@Component({
  components: { SortHeader }
})
export default class Trades extends Vue {
  trades: FlexTrade[] = []
  symbols = new Set<string>()
  loading = false
  search: { selectedSymbols: string[]; start: string; end: string; includeFee: boolean; contractType: string[] } = {
    selectedSymbols: new Array<string>(),
    start: null,
    end: null,
    includeFee: false,
    contractType: []
  }

  profits: { Count: number; Realized: number; Unrealized: number } = { Count: null, Realized: null, Unrealized: null }

  async mounted () {
    await this.loadData(false)
  }

  async loadData (forceUpdate: boolean) {
    this.loading = true
    const data = await reportService.getTrades(null, forceUpdate, true)
    this.loading = false
    if (data.Error) {
      await this.$alert(data.Error)
      return
    }
    pageTrades = data.Result
    const arr = []
    for (const item of data.Result) {
      if (item.SymbolName) {
        arr.push(utility.getSymbolDisplay(item.SymbolName, item.Currency))
      }
    }
    utility.sortArr(arr)
    utility.freezeArrayAndItems(arr)
    arr.forEach(p => this.symbols.add(p))
    this.trades = utility.freezeArrayItems(pageTrades)
    await this.calc()
  }

  private async filterSymbols () {
    const start = utility.parseDate(this.search.start)
    const end = utility.parseDate(this.search.end)
    let filter = pageTrades.filter(p => this.search.selectedSymbols.length === 0 || this.search.selectedSymbols.find(q => utility.iSameSymbol(q, p.SymbolName, p.Currency)))
    filter = filter.filter(p => start == null || utility.parseDate(p.TradeDate) >= start)
    filter = filter.filter(p => end == null || utility.parseDate(p.TradeDate) <= end)
    filter = filter.filter(p => this.search.contractType.length === 0 || this.search.contractType.indexOf(p.SecType) >= 0)
    this.trades = utility.freezeArrayItems(filter)
    await this.calc()
  }

  async feeChanged () {
    await this.calc()
  }

  async calc () {
    const data = await reportService.getDayProfits(this.search.selectedSymbols, this.search.start, this.search.end, this.search.contractType, this.search.includeFee)
    if (data.Error) {
      await this.$alert(data.Error)
      return
    }
    this.profits = {
      Realized: data.Result.Stock.Total.Realized + data.Result.Option.Total.Realized,
      Unrealized: data.Result.Stock.Total.Unrealized + data.Result.Option.Total.Unrealized,
      Count: data.Result.Stock.Total.Count + data.Result.Option.Total.Count
    }
  }

  destroyed () {
    pageTrades = []
  }
}
